import axios from "axios";
import init from "./init";
import { Notification } from 'element-ui'
import router from "../route/router"
import curl from "@/utils/api";

let base_url = process.env.VUE_APP_GLOB_API_URL;
let url_white_list = init.url_white_list;

let request = axios.create({
  baseURL: base_url,
  timeout: 5 * 60 * 1000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  if (config.url.indexOf("http") != -1) {
    config.baseURL = "";
  } else {
    if (!base_url) {
      Notification({
        title: '请配置config文件base_url',
        type: 'error'
      });
      return Promise.reject('请配置config文件base_url');
    }
    if (localStorage.getItem("token")) {
      if (url_white_list != null) {
        if (url_white_list.indexOf(config.url) == -1) {
          config.headers["Authorization"] = localStorage.getItem("token");
        }
      } else {
        config.headers["Authorization"] = localStorage.getItem("token");
      }
    }
  }
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  if (response.config.baseURL == "") {
    return Promise.resolve(response)
  }
  return Promise.resolve(response)

}, function (error) {
  if (error.response && error.response.status && error.response.status == 401) {
    if (localStorage.getItem("refresh_token")) {
      let data = { refreshToken: localStorage.getItem("refresh_token") };
      curl.get("/client/cli/refresh", data).then((res) => {
        if (res && res.data && res.data.access_token) {
          localStorage.setItem("token", "Bearer " + res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("user_id", res.data.user_id);
          window.location.reload();
        } else {
          router.push({ path: "/login" });
        }
      });
    } else {
      router.push({ path: "/login" });
    }
  } else {
    if (error.code === "ECONNABORTED") {
      Notification({
        title: '连接服务器请求超时！',
        type: 'error',
      });
    }
    return Promise.reject('网络不通！请检查网络')
  }
})


export default request;








